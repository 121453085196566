<template>
    <div class="businessList">
        <div>
            <!-- <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="140px"
        size="small"
      >
        <el-row :gutter="20">
          <el-col :span="5">
            <el-form-item
              label="商家名称："
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-input
                v-model="searchForm.business_name"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="一级机构"
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-select
                style="width: 100%"
                v-model="searchForm.senior_id"
                :remote-method="seniorRemoteMethod"
                :loading="seniorLoading"
                placeholder="请输入关键词"
                @change="onSeniorChange"
                @clear="onSeniorClear"
                clearable
                filterable
                remote
              >
                <el-option
                  v-for="item in seniorList"
                  :key="item.senior_id"
                  :label="item.senior_name"
                  :value="item.senior_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="商家主体"
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-select
                style="width: 100%"
                v-model="searchForm.entity_id"
                :remote-method="entityRemoteMethod"
                :loading="entityLoading"
                placeholder="请输入关键词"
                @change="onEntityChange"
                @clear="onEntityClear"
                clearable
                filterable
                remote
              >
                <el-option
                  v-for="item in entityList"
                  :key="item.entity_id"
                  :label="item.entity_name"
                  :value="item.entity_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="审核状态"
              label-width="100px"
              style="margin-bottom: 15px"
            >
              <el-select
                style="width: 100%"
                v-model="searchForm.audit_status"
                clearable
              >
                <el-option
                  v-for="item in auditStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div>
            <el-button type="primary" size="small" @click="serchData"
              >查询</el-button
            >
          </div>
        </el-row>
      </el-form> -->
        </div>
        <div class="derive">
            <el-button size="small" type="primary" @click="createCircles()">添加</el-button>
        </div>
        <base-table :columns="circleList" :data="tableData" :pagination="searchForm" :total="total" @getData="initData"
            :loading="loading" :isPaginationShow="true" wrapperHeight="calc(100% - 45px)">
            <el-table-column slot="status" label="状态" align="center" width="180">
                <template slot-scope="scope">
                    <div>{{ scope.row.status == 1 ?'可用':'不可用' }}</div>
                </template>
            </el-table-column>
            <el-table-column slot="circleIcon" label="图标" align="center" width="180">
                <template slot-scope="scope">
                    <el-image :src="scope.row.circleIcon" fit="cover" style="width: 80px;height: 80px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column slot="options" label="操作" align="center" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button type="text" size="small"
                        @click="editBusiness(scope.row)">修改</el-button>
                    <el-button type="text" size="small"
                        @click="delBusiness(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </base-table>
        <circleModel ref="circleModel"></circleModel>
    </div>
</template>
<script>
import columns from "./columns";
import { getCircleList } from '@/api/car'
import circleModel from './circle-modal.vue'
export default {
    components: {
        circleModel: circleModel
    },
    mounted() {
        this.initData()
    },
    data() {
        return {
            ...columns,
            total: 0,
            loading: false,
            tableData: [],
            searchForm: {
                page: 1,
                pageCount: 10
            },
        }
    },
    methods: {
        async initData() {
            const { code, data } = await getCircleList(this.searchForm)
            if (code == 200) {
                this.tableData = data.dataList
                this.total = data.count
            }
        },
        createCircles() {
            this.$refs.circleModel.isShow('new')
        },
        editBusiness(info) {
            this.$refs.circleModel.isShow('edit', info)
        }
    }
}
</script>
<style scoped lang="scss">
.businessList {
    .derive {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
}
</style>