import request from "@/utils/request";

export function getCircleList(params) {
  return request.post("/api/getCircleList", params);
}

export function createCircle(params) {
  return request.post("/api/createCircle", params);
}

export function editCircle(params) {
  return request.post("/api/editCircle", params);
}

export function getCircleTypeList(params) {
  return request.post("/api/getCircleTypeList", params);
}

export function createCircleType(params) {
  return request.post("/api/createCircleType", params);
}

export function modifyCircleType(params) {
  return request.post("/api/modifyCircleType", params);
}