<template>
  <div>
    <el-dialog :title="isEdit ? '修改圈子' : '新建圈子'" width="770px" :close-on-click-modal="false"
      :visible.sync="dialogFormVisible" custom-class="customW" :before-close="handleClose">
      <el-form ref="cirlceForm" :model="cirlceForm" label-width="200px" size="small" :rules="rules">
        <el-form-item label="圈子名称" label-width="100px" prop="circleName" style="margin-bottom: 15px">
          <el-input v-model="cirlceForm.circleName" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="圈子公告" label-width="100px" prop="circleTop" style="margin-bottom: 15px">
          <el-input v-model="cirlceForm.circleTop" clearable placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="圈子类型" label-width="100px" prop="circleTypeIds" style="margin-bottom: 15px">
          <el-select v-model="cirlceForm.circleTypeIds" multiple placeholder="请选择">
            <el-option v-for="item in circleType" :key="item.value" :label="item.label" :value="item.value" >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="圈子图标" label-width="100px" prop="circleIcon" style="margin-bottom: 15px">
          <el-upload class="upload-demo" :action="url" :on-preview="handlePreview" :on-remove="handleRemove"
            :on-success="handSuccess" :headers="header" :file-list="cirlceForm.fileList" list-type="picture"
            :with-credentials="true" :limit="1">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CONFIG from '../../utils/config'
import { createCircle, editCircle, getCircleTypeList } from '@/api/car'
export default {
  name: "operating-record",
  mounted() {
    this.url = (CONFIG.ENV_TEST ? CONFIG.BASE_URL_OFF : CONFIG.BASE_URL) + '/api/uploadIcon'
    this.initCircleType()
  },
  data() {

    return {
      isEdit: false,
      dialogFormVisible: false,
      cirlceForm: {
        circleName: '',
        circleTop: '',
        circleIcon: '',
        circleTypeIds: ''
      },
      circleType: [],
      url: '',
      header: { 'token': localStorage.getItem('eg_group_token') },
      rules: {
        circleName: [
          { required: true, message: "请输入圈子名称", trigger: "change" },
        ],
        circleIcon: [
          { required: true, message: "至少上传一张图片", trigger: "change" },
        ],
        circleTypeIds: [
          { required: true, message: "至少选择一个类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    async initCircleType() {
      const { code, data } = await getCircleTypeList()
      if (code == 200) {
        this.circleType=[]
        data.dataList.map(item=> {
          this.circleType.push({
            value: item.circleTypeId,
            label: item.circleTypeName
          })
        })
        console.log(this.circleType)
      }
    },
    async isShow(type, info) {
      this.dialogFormVisible = true;
      if (type == "edit") {
        this.isEdit = true;
        this.cirlceForm.circleId = info.circleId ? info.circleId : ''
        this.cirlceForm.circleName = info.circleName ? info.circleName : ''
        this.cirlceForm.circleTop = info.circleTop ? info.circleTop : ''
        this.cirlceForm.circleIcon = info.circleIcon ? info.circleIcon : ''
        this.cirlceForm.circleTypeIds = info.circleTypeIds ? info.circleTypeIds : ''
        this.cirlceForm.fileList = [{
          name: '图标',
          url: info.circleIcon
        }]
      } else {
        this.isEdit = false;
      }
    },

    handleClose(done) {
      done();
      this.close();
    },
    close() {
      this.dialogFormVisible = false;
      this.$refs.cirlceForm.resetFields();
      this.cirlceForm.fileList = []
    },
    getFile(file) {
      console.log(file)
    },
    confirm() {
      this.$refs['cirlceForm'].validate(async (valid) => {
        if (valid) {
          console.log(this.cirlceForm)
          // this.isEdit 
          this.cirlceForm.circleTypeIds = this.cirlceForm.circleTypeIds.join(',')
          let res = this.isEdit? await editCircle(this.cirlceForm): await createCircle(this.cirlceForm)
          if (res.code == 200) {
            this.close()
            this.$parent.initData()
          }
        }
      });
    },
    selectOnchange(e) {
      console.log(e)
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handSuccess(e) {
      console.log(e)
      if (e.code == 200) {
        this.cirlceForm.circleIcon = e.data.icon
      }
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;

  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;

    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }

  .classification {
    margin: 10px 40px;

    .content-form {
      margin: 0px 10px;
    }
  }

  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>